import React, {useState, useEffect, useRef, useCallback} from "react";

import { Link } from "react-router-dom";
import "./Footer.scss";
import banner from "../../img/banner_campaign.png"
import { ReactComponent as Logo } from "../../img/logo.svg"
import { ReactComponent as NewWindow } from "../../img/icon_new-window.svg"
import { HashLink } from "react-router-hash-link";


const Footer = () => {
  const [isDisplay, setIsDisplay] = useState(false)
  const isRunning = useRef(false)

  const scrollWidthOffset = (el) => {
		const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
		const yOffset = -100;
		window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
	};

  const isScrollToggle = useCallback(() => {
    if (isRunning.current) return
    isRunning.current = true;
    const html = document.documentElement;
    const scrollTop = window.pageYOffset || html.scrollTop;
    const scrollBottom = html.scrollHeight - html.clientHeight - scrollTop;

    requestAnimationFrame(() => {
      if (scrollTop > 200) {
        setIsDisplay(true)
      } else {
        setIsDisplay(false)
      }
      if (scrollBottom < 200) {
        setIsDisplay(false)
      }
      isRunning.current = false
    })
  }, [])

  useEffect(() => {
    document.addEventListener('scroll', isScrollToggle, { passive: true })
    return () => {
      document.removeEventListener('scroll', isScrollToggle, { passive: true })
    }
  }, [])


  return (
    <>
    <div className={`banner_campaign ${isDisplay ? "is_display" : ""}`}>
        <HashLink
          to="/#sec_form"
          scroll={(el) => scrollWidthOffset(el)}
        // to="//development.ap.scrw.jp/"
        // target="_blank"
        // rel="noopener noreferrer"
        >
        <img src={banner} alt="2週間おためし キャンペーン中"/>
      </HashLink>
    </div>
    <div className="c_footer">
			<div className="flex-wrap">
				<div className="logo_wrap">
					<div className="logo"><Link to="/"><Logo /></Link></div>
					<a href="https://aluco.jp/" target="_blank" rel="noopener noreferrer">［ 運営会社 ］株式会社Aluco <NewWindow className="new-window"  /></a>
				</div>
				<div className="nav_wrap">
					<Link className="nav-item" to="/flow">ご利用までの流れ</Link>
					<Link className="nav-item" to="/privacy">プライバシーポリシー</Link>
					<Link className="nav-item" to="/terms">利用規約</Link>
					<a className="nav-item" href="https://d.scrw.jp" target="_blank" rel="noopener noreferrer">ドキュメント <NewWindow className="new-window" /></a>
				</div>
			</div>
			<div className="copyright">&copy;2020 Aluco all rights reserved.</div>
      </div>
      </>
	);
};

export default Footer;
