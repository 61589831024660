import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Footer, Header, ScrollToTop } from './components';
import Home from './pages/Home';
import Flow from './pages/Flow';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import Thanks from './pages/Thanks';


const App = () => {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <div className="all_wrapper">
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/flow" exact component={Flow} />
          <Route path="/privacy" exact component={PrivacyPolicy} />
          <Route path="/terms" exact component={Terms} />
          <Route path="/thanks" exact component={Thanks} />
        </Switch>
        </div>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
